import React from "react";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import DemoPage from "../../components/ColoradoPortedComps/DemoPage";
import LocalSlider from "../../components/ColoradoPortedComps/LocalSlider";
import PartnerLogos from "../../components/ColoradoPortedComps/PartnerLogos";
import { georgiaTestimonialsData } from "../../data/local/georgia-data";

import ogImage from "../../images/global_assets/og-image.png";

const Demo = () => {
  return (
    <Layout isLanding phoneNumber="+18882246506" phoneText="(888) 224-6506">
      <SEO
        title="SpotOn | Restaurant Point Of Sale"
        description="SpotOn makes it easier than ever for on-the-go food businesses like food trucks to take orders, print tickets, process payments, and stay online just about anywhere."
        image={`https://spoton.com/${ogImage}`}
      />
      <DemoPage
        formId="d4131808-6648-449c-bce6-16293f726d13"
        leadType="restaurants"
      />
      <br />
      <br />
      <br />
      <br />
      <PartnerLogos />
      <LocalSlider testimonialsData={georgiaTestimonialsData} />
    </Layout>
  );
};

export default Demo;
